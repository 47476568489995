<template>
<div>
    <div class="kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid">
        <div class="kt-portlet">
            <div class="kt-portlet__head">
                <div class="kt-portlet__head-label">
                    <h3 class="kt-portlet__head-title">
                        Set School Google Admin
                    </h3>
                </div>
            </div>

            <div class="kt-portlet__body">
                <template v-if="editMode">
                    <div class="form-group w-100 ml-4">
                        <label>Change Credentials</label>
                    </div>
                    <div class="col-12">
                        <div class="form-group px-3">
                            <div class="input-group">
                                <select
                                    v-model="selectedGoogleAccountId"
                                    class="custom-select form-control"
                                >
                                    <option value="" />
                                    <option
                                        v-for="t in teachers"
                                        :key="`staff_${t.schoolStaffId}`"
                                        :value="t.googleAccountId"
                                    >
                                        {{ t.googleEmail }}
                                    </option>
                                </select>
                                <div class="input-group-append">
                                    <template v-if="saving">
                                        <span
                                            class="input-group-text d-block"
                                            style="min-width: 44px;"
                                        >
                                            <i
                                                style="margin-top: 10px"
                                                class="kt-spinner kt-spinner--sm kt-spinner--primary d-block"
                                            />
                                        </span>
                                    </template>
                                    <template v-else-if="success">
                                        <span class="input-group-text">
                                            <i class="la la-check kt-font-success" />
                                        </span>
                                    </template>
                                    <template v-else-if="!saving">
                                        <button
                                            v-if="selectedGoogleAccountId"
                                            class="btn btn-secondary kt-font-bold kt-font-primary"
                                            @click.stop.prevent="save"
                                        >
                                            Set
                                        </button>
                                        <span
                                            v-else
                                            class="input-group-text"
                                            @click.stop.prevent="showEditor = false"
                                        >
                                            <i class="la la-close" />
                                        </span>
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>
                </template>
                <template v-else>
                    <div class="col-8 pl-3">
                        <div class="kt-widget4 pt-2 pb-2">
                            <div
                                v-if="proxyCreds"
                                class="kt-widget4__item"
                            >
                                <div class="kt-widget4__pic kt-widget4__pic--pic">
                                    <UserAvatar :avatar-user="proxyCreds">
                                        <div class="kt-badge kt-badge--lg kt-badge--success">
                                            {{ proxyCreds.lastName.substring(0,1) }}{{ proxyCreds.firstName.substring(0,1) }}
                                        </div>
                                    </UserAvatar>
                                </div>
                                <div class="kt-widget4__info">
                                    <router-link
                                        class="kt-widget4__username"
                                        :to="{name: 'TeacherInfo', params: {schoolEmail: proxyCreds.schoolEmail}}"
                                    >
                                        {{ proxyCreds.lastName }}, {{ proxyCreds.firstName }}
                                    </router-link>
                                    <p class="kt-widget4__text email-label">
                                        Admin Google Credentials
                                    </p>
                                </div>
                            </div>
                            <div
                                v-else
                                class="kt-widget4__item"
                            >
                                <div class="kt-widget4__info">
                                    <span class="kt-widget4__username">
                                        Admin Credentials Not Set
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="form-text kt-font-md text-muted px-3">
                        These credentials will be used when linking courses and when performing google rostering.
                    </div>
                </template>
            </div>
            "
            <div
                v-if="!showEditor"
                class="kt-portlet__foot"
            >
                <div class="row">
                    <a
                        class="btn btn-primary mt-2 ml-4 pull-right"
                        href="#"
                        @click.stop.prevent="showEditor = true"
                    >
                        <i class="la la-user " />
                        Change Admin Account
                    </a>
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script lang="ts">
import Vue from 'vue';
import UserAvatar from '../components/UserAvatar.vue';
import userMixins from '../store/mixins/userMixins';
import googleCourseMixins from '../store/mixins/googleCourseMixins';
import courseMixins from '../store/mixins/courseMixins';
import * as network from '../network';

export default Vue.extend({
    name: 'AdminGoogleAccount',
    components: {
        UserAvatar,
    },
    mixins: [
        userMixins,
        googleCourseMixins,
        courseMixins,
    ],
    data() {
        return {
            saving: false,
            success: false,
            showEditor: false,
            selectedGoogleAccountId: null,
        };
    },
    computed: {
        googleAccountId: {
            get() {
                return this.$store.state.user.school.googleAccountId;
            },
            set(googleAccountId) {
                this.selectedGoogleAccountId = googleAccountId;
            },
        },
        teachers() {
            const { teachers } = this.$store.state.database;
            return teachers
                .filter((t) => t.hasGoogleAccount)
                .sort((a, b) => ((`${a.googleEmail}` > `${b.googleEmail}`) ? 1 : -1));
        },
        editMode() {
            const { showEditor } = this;
            return (showEditor);
        },
        proxyCreds() {
            const { googleAccountId } = this;
            const { teachers } = this.$store.state.database;
            return teachers.find((t) => googleAccountId && t.googleAccountId == googleAccountId) || null;
        },
    },
    methods: {
        save() {
            const { selectedGoogleAccountId, teachers, $store } = this;
            if (!selectedGoogleAccountId) return;

            if (this.saving) return;
            this.saving = true;
            const v = this;

            const teacher = teachers.find((t) => t.googleAccountId == selectedGoogleAccountId);
            if (!teacher) return;
            const { googleAccountId } = teacher;
            const { schoolId, schoolTermId } = this.$_userMixins_school;

            const params = {
                url: {
                    schoolId,
                    schoolTermId,
                },
                body: {
                    googleAccountId,
                },
            };

            const { showError } = this;
            network.google.setAdminCredentials(params, (err) => {
                v.saving = false;
                if (err) return showError(err);
                v.success = true;
                $store.dispatch('refreshSession');
                setTimeout(() => {
                    v.showEditor = false;
                    v.success = false;
                }, 2000);
            });
        },
    },
});
</script>
