var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("div", [
    _c(
      "div",
      {
        staticClass:
          "kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid",
      },
      [
        _c("div", { staticClass: "kt-portlet" }, [
          _vm._m(0),
          _c(
            "div",
            { staticClass: "kt-portlet__body" },
            [
              _vm.editMode
                ? [
                    _vm._m(1),
                    _c("div", { staticClass: "col-12" }, [
                      _c("div", { staticClass: "form-group px-3" }, [
                        _c("div", { staticClass: "input-group" }, [
                          _c(
                            "select",
                            {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.selectedGoogleAccountId,
                                  expression: "selectedGoogleAccountId",
                                },
                              ],
                              staticClass: "custom-select form-control",
                              on: {
                                change: function ($event) {
                                  var $$selectedVal = Array.prototype.filter
                                    .call($event.target.options, function (o) {
                                      return o.selected
                                    })
                                    .map(function (o) {
                                      var val =
                                        "_value" in o ? o._value : o.value
                                      return val
                                    })
                                  _vm.selectedGoogleAccountId = $event.target
                                    .multiple
                                    ? $$selectedVal
                                    : $$selectedVal[0]
                                },
                              },
                            },
                            [
                              _c("option", { attrs: { value: "" } }),
                              _vm._l(_vm.teachers, function (t) {
                                return _c(
                                  "option",
                                  {
                                    key: `staff_${t.schoolStaffId}`,
                                    domProps: { value: t.googleAccountId },
                                  },
                                  [_vm._v(" " + _vm._s(t.googleEmail) + " ")]
                                )
                              }),
                            ],
                            2
                          ),
                          _c(
                            "div",
                            { staticClass: "input-group-append" },
                            [
                              _vm.saving
                                ? [_vm._m(2)]
                                : _vm.success
                                ? [_vm._m(3)]
                                : !_vm.saving
                                ? [
                                    _vm.selectedGoogleAccountId
                                      ? _c(
                                          "button",
                                          {
                                            staticClass:
                                              "btn btn-secondary kt-font-bold kt-font-primary",
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                $event.preventDefault()
                                                return _vm.save.apply(
                                                  null,
                                                  arguments
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v(" Set ")]
                                        )
                                      : _c(
                                          "span",
                                          {
                                            staticClass: "input-group-text",
                                            on: {
                                              click: function ($event) {
                                                $event.stopPropagation()
                                                $event.preventDefault()
                                                _vm.showEditor = false
                                              },
                                            },
                                          },
                                          [
                                            _c("i", {
                                              staticClass: "la la-close",
                                            }),
                                          ]
                                        ),
                                  ]
                                : _vm._e(),
                            ],
                            2
                          ),
                        ]),
                      ]),
                    ]),
                  ]
                : [
                    _c("div", { staticClass: "col-8 pl-3" }, [
                      _c("div", { staticClass: "kt-widget4 pt-2 pb-2" }, [
                        _vm.proxyCreds
                          ? _c("div", { staticClass: "kt-widget4__item" }, [
                              _c(
                                "div",
                                {
                                  staticClass:
                                    "kt-widget4__pic kt-widget4__pic--pic",
                                },
                                [
                                  _c(
                                    "UserAvatar",
                                    {
                                      attrs: { "avatar-user": _vm.proxyCreds },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "kt-badge kt-badge--lg kt-badge--success",
                                        },
                                        [
                                          _vm._v(
                                            " " +
                                              _vm._s(
                                                _vm.proxyCreds.lastName.substring(
                                                  0,
                                                  1
                                                )
                                              ) +
                                              _vm._s(
                                                _vm.proxyCreds.firstName.substring(
                                                  0,
                                                  1
                                                )
                                              ) +
                                              " "
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "div",
                                { staticClass: "kt-widget4__info" },
                                [
                                  _c(
                                    "router-link",
                                    {
                                      staticClass: "kt-widget4__username",
                                      attrs: {
                                        to: {
                                          name: "TeacherInfo",
                                          params: {
                                            schoolEmail:
                                              _vm.proxyCreds.schoolEmail,
                                          },
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(_vm.proxyCreds.lastName) +
                                          ", " +
                                          _vm._s(_vm.proxyCreds.firstName) +
                                          " "
                                      ),
                                    ]
                                  ),
                                  _c(
                                    "p",
                                    {
                                      staticClass:
                                        "kt-widget4__text email-label",
                                    },
                                    [_vm._v(" Admin Google Credentials ")]
                                  ),
                                ],
                                1
                              ),
                            ])
                          : _c("div", { staticClass: "kt-widget4__item" }, [
                              _vm._m(4),
                            ]),
                      ]),
                    ]),
                    _c(
                      "div",
                      { staticClass: "form-text kt-font-md text-muted px-3" },
                      [
                        _vm._v(
                          " These credentials will be used when linking courses and when performing google rostering. "
                        ),
                      ]
                    ),
                  ],
            ],
            2
          ),
          _vm._v(' " '),
          !_vm.showEditor
            ? _c("div", { staticClass: "kt-portlet__foot" }, [
                _c("div", { staticClass: "row" }, [
                  _c(
                    "a",
                    {
                      staticClass: "btn btn-primary mt-2 ml-4 pull-right",
                      attrs: { href: "#" },
                      on: {
                        click: function ($event) {
                          $event.stopPropagation()
                          $event.preventDefault()
                          _vm.showEditor = true
                        },
                      },
                    },
                    [
                      _c("i", { staticClass: "la la-user" }),
                      _vm._v(" Change Admin Account "),
                    ]
                  ),
                ]),
              ])
            : _vm._e(),
        ]),
      ]
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "kt-portlet__head" }, [
      _c("div", { staticClass: "kt-portlet__head-label" }, [
        _c("h3", { staticClass: "kt-portlet__head-title" }, [
          _vm._v(" Set School Google Admin "),
        ]),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "form-group w-100 ml-4" }, [
      _c("label", [_vm._v("Change Credentials")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c(
      "span",
      {
        staticClass: "input-group-text d-block",
        staticStyle: { "min-width": "44px" },
      },
      [
        _c("i", {
          staticClass: "kt-spinner kt-spinner--sm kt-spinner--primary d-block",
          staticStyle: { "margin-top": "10px" },
        }),
      ]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("span", { staticClass: "input-group-text" }, [
      _c("i", { staticClass: "la la-check kt-font-success" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c,
      _setup = _vm._self._setupProxy
    return _c("div", { staticClass: "kt-widget4__info" }, [
      _c("span", { staticClass: "kt-widget4__username" }, [
        _vm._v(" Admin Credentials Not Set "),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }